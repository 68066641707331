<template>
  <div class="email-app-details">
    <aom-skeleton-loader v-if="isLoading" />
    <!-- Email Header -->
    <div
      v-else
      class="email-detail-header"
    >
      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="
              $store.state.appConfig.isRTL
                ? 'ChevronRightIcon'
                : 'ChevronLeftIcon'
            "
            size="20"
            class="align-bottom"
            @click="$emit('close-email-view')"
          />
        </span>
        <h4
          v-if="inviteDetailData.latest_message"
          class="email-subject mb-0"
        >
          {{ inviteDetailData.latest_message[0].subject }}
        </h4>
      </div>
    </div>

    <!-- Email Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area p-0"
    >
      <!-- Earlier Email Messages -->
      <b-card no-body>
        <b-card-header
          v-if="inviteTo"
          class="email-detail-head"
        >
          <div
            class="user-details d-flex justify-content-between align-items-center flex-wrap"
          >
            <b-avatar
              size="48"
              :text="userInitials(inviteDetailData.created_by)"
              :src="userProfileImage(inviteDetailData.created_by)"
              class="mr-75"
            />
            <div class="mail-items">
              <h5 class="mb-0">
                <!-- eslint-disable vue/no-parsing-error -->
                {{
                  `${inviteDetailData.created_by.full_name} <${inviteDetailData.created_by.email}>`
                }}
                <!-- eslint-enable -->
              </h5>
              <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-0"
                class="email-info-dropup"
              >
                <template #button-content>
                  <span
                    class="font-small-3 text-muted mr-25 d-inline-block text-truncate"
                    style="max-width: 500px"
                  >
                    {{ `Sent to ${inviteTo}` }}
                  </span>
                  <feather-icon
                    icon="ChevronDownIcon"
                    size="10"
                  />
                </template>
                <table class="table table-sm table-borderless font-small-3">
                  <tbody>
                    <tr>
                      <td class="text-right text-muted align-top">
                        From:
                      </td>
                      <td>{{ inviteDetailData.created_by.email }}</td>
                    </tr>
                    <tr>
                      <td class="text-right text-muted align-top">
                        Sent to:
                      </td>
                      <td v-if="inviteTo">
                        <tr>
                          <td class="p-0">
                            {{ inviteTo }}
                          </td>
                        </tr>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-right text-muted align-top">
                        Date:
                      </td>
                      <td>{{ formatDate(inviteDetailData.created_at) }}</td>
                    </tr>
                  </tbody>
                </table>
              </b-dropdown>
            </div>
          </div>
          <div class="mail-meta-item d-flex align-items-center">
            <b-button
              variant="primary"
              size="sm"
              class="mr-1"
              @click="viewSentMessageList(inviteDetailData.id)"
            >
              <span class="text-nowrap">View recipients</span>
            </b-button>
            <small class="mail-date-time text-muted">{{
              formatDate(inviteDetailData.created_at)
            }}</small>
          </div>
        </b-card-header>

        <b-card-body class="mail-message-wrapper pt-2">
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="inviteDetailData.latest_message"
            class="mail-message"
            v-html="DOMPurify.sanitize(inviteDetailData.latest_message[0].body)"
          />
          <!-- eslint-enable -->
        </b-card-body>
      </b-card>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BCard,
  BDropdown,
  BCardHeader,
  BCardBody,
  BAvatar,
  BButton
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { ref, watch } from "@vue/composition-api";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { formatDateTimeFromIsoDateTime } from "@/libs/utils";
import useEmail from "@/views/apps/champion/communication/useEmail";
import DOMPurify from 'dompurify';
export default {
  components: {
    BButton,
    BCard,
    VuePerfectScrollbar,
    AomSkeletonLoader,
    BDropdown,
    BCardHeader,
    BCardBody,
    BAvatar,
  },
  props: {
    inviteDetailData: {
      type: Object,
      required: true,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      shallShowEmailComposeModal: false,
    };
  },
  computed: {
    inviteTo() {
      if (this.inviteDetailData && this.inviteDetailData.topic_recipients) {
        return this.inviteDetailData.topic_recipients[0].recipient
          ? this.inviteDetailData.topic_recipients[0].recipient.email
          : this.inviteDetailData.topic_recipients[0].raw_email_address;
      }
      return "";
    },
  },
  methods: {
    viewSentMessageList(id) {
      this.$router.push({
        name: "champion-program-communication-sent-messages",
        params: {
          id: this.$route.params.id,
          topicId: id
        }
      });
    },
    formatDate(datetime) {
      return formatDateTimeFromIsoDateTime(datetime, true, "DD MMM YYYY");
    },
    userInitials(userName) {
      const firstInt = Array.from(userName?.first_name)[0];
      const lastInt = Array.from(userName?.last_name)[0];
      return `${firstInt}${lastInt}`;
    },
    userProfileImage(user) {
      return user.profile_image?.url || '';
    },
  },
  setup(props) {
    const { resolveLabelColor } = useEmail();

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    const showWholeThread = ref(false);

    watch(
      () => props.inviteDetailData.id,
      () => {
        showWholeThread.value = false;
      }
    );

    return {
      perfectScrollbarSettings,
      showWholeThread,
      resolveLabelColor,
      DOMPurify
    };
  },
};
</script>
