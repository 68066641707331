<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = false"
    />

    <!-- Email List -->
    <div class="email-app-list">
      <!-- App Searchbar Header -->
      <div class="email-app-list">
        <!-- App Searchbar Header -->
        <div class="app-fixed-search d-flex align-items-center justify-content-between">
          <!-- Toggler -->
          <div class="sidebar-toggle ml-1">
            <feather-icon
              icon="MenuIcon"
              size="21"
              class="cursor-pointer d-block d-lg-none"
              @click="mqShallShowLeftSidebar = true"
            />
          </div>
          <div class="d-flex align-content-center justify-content-between w-100">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search email"
              />
            </b-input-group>
          </div>

          <b-button
            :to="{ name: 'champion-program-invite-templates'}"
            variant="primary"
          >
            <feather-icon
              size="16"
              icon="LayersIcon"
              class="mr-50"
            />
            Templates
          </b-button>
        </div>
        <!-- Emails List -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="email-user-list scroll-area"
        >
          <aom-skeleton-loader v-if="isLoading" />
          <ul class="email-media-list">
            <b-media
              v-for="email in emailList"
              :key="email.id"
              tag="li"
              no-body
              :class="{ 'mail-read': email.isRead }"
              @click="viewTopicMessage(email)"
            >
              <b-media-body>
                <div class="mail-details mb-0">
                  <div
                    v-if="email.topic_recipients.length > 0"
                    class="mail-items"
                  >
                    <h5 class="m-0">
                      <b-avatar
                        :text="Array.from(email.topic_recipients[0].raw_email_address)[0]"
                        size="2.5rem"
                      />
                      <span class="d-none d-sm-inline font-weight-bold">
                        {{ email.topic_recipients[0].raw_email_address }} {{ email.topic_recipients.length > 1 ? "+ more": "" }}
                      </span>
                      <span class="d-none d-sm-inline ml-1">
                        {{ email.latest_message[0].subject }}
                      </span>
                      <!-- For Mobile -->
                      <p class="d-block d-sm-none font-weight-bold mt-1">
                        {{ email.topic_recipients[0].raw_email_address }}
                      </p>
                      <p class="d-block d-sm-none">
                        {{ email.latest_message[0].subject }}
                      </p>
                    </h5>
                    <span class="text-truncate">{{ email.name }}</span>
                  </div>
                  <div class="mail-meta-item">
                    <span class="mail-date">{{ formatDateToMonthShort(email.updated_at, { hour: 'numeric', minute: 'numeric', }) }}</span>
                  </div>
                </div>
              </b-media-body>
            </b-media>
          </ul>
          <div
            class="no-results"
            :class="{'show': !emailList.length && !isLoading}"
          >
            <h5>No Items Found</h5>
          </div>
        </vue-perfect-scrollbar>
      </div>
    </div>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <email-left-sidebar
        :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
        :class="{ show: mqShallShowLeftSidebar }"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

    <!-- Compose Email Modal -->
    <email-compose
      v-model="shallShowEmailComposeModal"
      :is-program-communications="true"
      :type="emailComposeTypes.INVITES"
      @emailAdded="fetchProgramInviteEmails"
      @emailSent="emailSent"
    />

    <!-- Invite detail -->
    <invite-details
      :class="{'show': showInviteDetails}"
      :is-loading="isLoadingMsg"
      :invite-detail-data="inviteDetailData"
      @close-email-view="showInviteDetails = false"
    />
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BMedia,
  BMediaBody,
  BButton,
  BAvatar
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { formatDateToMonthShort } from "@core/utils/filter";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import EmailLeftSidebar from "@/views/apps/champion/communication/EmailLeftSidebar.vue";
import { commsService } from "@services";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import EmailCompose from "@/views/components/forms/communication/EmailCompose.vue";
import { recipientTypes } from '@models';
import { emailComposeTypes } from "@/models/enums/emailComposeTypes";
import { topicTypes } from '@/models';
import InviteDetails from "./invites/InviteDetails.vue";
import _debounce from "lodash/debounce";

export default {
  components: {
    BMedia,
    BMediaBody,
    BButton,
    VuePerfectScrollbar,
    EmailLeftSidebar,
    EmailCompose,
    AomSkeletonLoader,
    InviteDetails,
    BAvatar,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput
  },
  data() {
    return {
      isLoading: false,
      isLoadingMsg: false,
      emailList: [],
      showInviteDetails: false,
      inviteDetailData: {},
      emailRecipients: [],
      emailsTotal: 0,
      searchQuery: undefined,
      emailComposeTypes
    };
  },
  watch: {
    searchQuery: _debounce(function (value) {
      this.fetchProgramInviteEmails({
        columnFilters: [{
          field: 'messages',
          value: value,
        }],
      });
    }, 1000),
  },
  created() {
    this.fetchProgramInviteEmails();
  },
  methods: {
    emailSent() {
      this.$toast(makeSuccessToast("Test email has been sent successfully to your email address."));
    },
    async fetchProgramInviteEmails(searchQuery) {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id;
        const response = await commsService.getProgramsComms(programId, {
          columnFilters: [{
            field: 'type_id',
            value: topicTypes.INVITE
          }].concat(searchQuery?.columnFilters ?? [])
        });
        const { items, total } = response.data;
        this.emailList = items.sort(function compare(a, b) {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        this.emailsTotal = total;
        if (this.$route.query.id) {
          const comm = this.emailList.find(r => Number(r.id) === Number(this.$route.query.id));
          if (comm) {
            this.viewTopicMessage(comm);
          }
        }
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast("Program Emails Not Loaded"));
      } finally {
        this.isLoading = false;
      }
    },
    handleSearchInvites(event) {
      const query = event.target.value;
      this.fetchProgramInviteEmails(query);
    },
    async viewTopicMessage(topic) {
      this.showInviteDetails = true;
      this.inviteDetailData = topic;
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };
    // Compose
    const shallShowEmailComposeModal = ref(false);

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    return {
      perfectScrollbarSettings,
      formatDateToMonthShort,
      shallShowEmailComposeModal,
      mqShallShowLeftSidebar,
      recipientTypes
    };
  },
};
</script>

<style lang="scss" scoped>
.email-app-list {
  background-color: #fff;
}
</style>

<style lang="scss">
@import "~@/assets/scss/base/pages/app-email.scss";
</style>
